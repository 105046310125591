import createLucideIcon from '../createLucideIcon';

const Speaker = createLucideIcon('Speaker', [
  [
    'rect',
    {
      x: '4',
      y: '2',
      width: '16',
      height: '20',
      rx: '2',
      ry: '2',
      key: '152kg8',
    },
  ],
  ['circle', { cx: '12', cy: '14', r: '4', key: '1jruaj' }],
  ['line', { x1: '12', y1: '6', x2: '12.01', y2: '6', key: 'fpk8as' }],
]);

export default Speaker;
