import createLucideIcon from '../createLucideIcon';

const Sliders = createLucideIcon('Sliders', [
  ['line', { x1: '4', y1: '21', x2: '4', y2: '14', key: '2cpl65' }],
  ['line', { x1: '4', y1: '10', x2: '4', y2: '3', key: '1b26kg' }],
  ['line', { x1: '12', y1: '21', x2: '12', y2: '12', key: 'fxobwr' }],
  ['line', { x1: '12', y1: '8', x2: '12', y2: '3', key: 'bkspcw' }],
  ['line', { x1: '20', y1: '21', x2: '20', y2: '16', key: 'b7lt1r' }],
  ['line', { x1: '20', y1: '12', x2: '20', y2: '3', key: 'inamez' }],
  ['line', { x1: '2', y1: '14', x2: '6', y2: '14', key: 'tezuxb' }],
  ['line', { x1: '10', y1: '8', x2: '14', y2: '8', key: '1w8tme' }],
  ['line', { x1: '18', y1: '16', x2: '22', y2: '16', key: '1gnq8h' }],
]);

export default Sliders;
