import createLucideIcon from '../createLucideIcon';

const GitPullRequest = createLucideIcon('GitPullRequest', [
  ['circle', { cx: '18', cy: '18', r: '3', key: '1xkwt0' }],
  ['circle', { cx: '6', cy: '6', r: '3', key: '1lh9wr' }],
  ['path', { d: 'M13 6h3a2 2 0 0 1 2 2v7', key: '1yeb86' }],
  ['line', { x1: '6', y1: '9', x2: '6', y2: '21', key: '79th4h' }],
]);

export default GitPullRequest;
