import createLucideIcon from '../createLucideIcon';

const SidebarOpen = createLucideIcon('SidebarOpen', [
  [
    'rect',
    {
      x: '3',
      y: '3',
      width: '18',
      height: '18',
      rx: '2',
      ry: '2',
      key: 'maln0c',
    },
  ],
  ['path', { d: 'M9 3v18', key: 'fh3hqa' }],
  ['path', { d: 'm14 9 3 3-3 3', key: '8010ee' }],
]);

export default SidebarOpen;
