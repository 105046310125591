import createLucideIcon from '../createLucideIcon';

const Instagram = createLucideIcon('Instagram', [
  [
    'rect',
    {
      x: '2',
      y: '2',
      width: '20',
      height: '20',
      rx: '5',
      ry: '5',
      key: 'cdfzoc',
    },
  ],
  [
    'path',
    { d: 'M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z', key: '9exkf1' },
  ],
  ['line', { x1: '17.5', y1: '6.5', x2: '17.51', y2: '6.5', key: '643fve' }],
]);

export default Instagram;
