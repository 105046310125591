import createLucideIcon from '../createLucideIcon';

const SunDim = createLucideIcon('SunDim', [
  ['path', { d: 'M12 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8z', key: '1r4kox' }],
  ['path', { d: 'M12 4h.01', key: '1ujb9j' }],
  ['path', { d: 'M20 12h.01', key: '1ykeid' }],
  ['path', { d: 'M12 20h.01', key: 'zekei9' }],
  ['path', { d: 'M4 12h.01', key: '158zrr' }],
  ['path', { d: 'M17.657 6.343h.01', key: '31pqzk' }],
  ['path', { d: 'M17.657 17.657h.01', key: 'jehnf4' }],
  ['path', { d: 'M6.343 17.657h.01', key: 'gdk6ow' }],
  ['path', { d: 'M6.343 6.343h.01', key: '1uurf0' }],
]);

export default SunDim;
