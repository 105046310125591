import createLucideIcon from '../createLucideIcon';

const LightbulbOff = createLucideIcon('LightbulbOff', [
  ['path', { d: 'M9 18h6', key: 'x1upvd' }],
  ['path', { d: 'M10 22h4', key: 'ceow96' }],
  ['path', { d: 'm2 2 20 20', key: '1ooewy' }],
  [
    'path',
    { d: 'M9 2.804A6 6 0 0 1 18 8a4.65 4.65 0 0 1-1.03 3', key: '1v6krz' },
  ],
  [
    'path',
    {
      d: 'M8.91 14a4.61 4.61 0 0 0-1.41-2.5C6.23 10.23 6 9 6 8a6 6 0 0 1 .084-1',
      key: '1jxmct',
    },
  ],
]);

export default LightbulbOff;
