import createLucideIcon from '../createLucideIcon';

const AlignVerticalJustifyCenter = createLucideIcon(
  'AlignVerticalJustifyCenter',
  [
    [
      'rect',
      { x: '5', y: '16', width: '14', height: '6', rx: '2', key: '1xmr5l' },
    ],
    [
      'rect',
      { x: '7', y: '2', width: '10', height: '6', rx: '2', key: '1dm79a' },
    ],
    ['path', { d: 'M2 12h20', key: '9i4pu4' }],
  ],
);

export default AlignVerticalJustifyCenter;
