import createLucideIcon from '../createLucideIcon';

const RussianRuble = createLucideIcon('RussianRuble', [
  ['path', { d: 'M14 11c5.333 0 5.333-8 0-8', key: '92e629' }],
  ['path', { d: 'M6 11h8', key: '1cr2u4' }],
  ['path', { d: 'M6 15h8', key: '1y8f6l' }],
  ['path', { d: 'M9 21V3', key: '1jd2g6' }],
  ['path', { d: 'M9 3h5', key: '8bgvcw' }],
]);

export default RussianRuble;
