import createLucideIcon from '../createLucideIcon';

const ImagePlus = createLucideIcon('ImagePlus', [
  [
    'path',
    {
      d: 'M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7',
      key: '31hg93',
    },
  ],
  ['line', { x1: '16', y1: '5', x2: '22', y2: '5', key: 'c5ve4s' }],
  ['line', { x1: '19', y1: '2', x2: '19', y2: '8', key: '12oc9j' }],
  ['circle', { cx: '9', cy: '9', r: '2', key: 'af1f0g' }],
  ['path', { d: 'm21 15-3.086-3.086a2 2 0 0 0-2.828 0L6 21', key: '1xmnt7' }],
]);

export default ImagePlus;
