import createLucideIcon from '../createLucideIcon';

const Chrome = createLucideIcon('Chrome', [
  ['circle', { cx: '12', cy: '12', r: '10', key: '1mglay' }],
  ['circle', { cx: '12', cy: '12', r: '4', key: '4exip2' }],
  ['line', { x1: '21.17', y1: '8', x2: '12', y2: '8', key: '1a1nqa' }],
  ['line', { x1: '3.95', y1: '6.06', x2: '8.54', y2: '14', key: 'irv2k6' }],
  ['line', { x1: '10.88', y1: '21.94', x2: '15.46', y2: '14', key: 'fokf7t' }],
]);

export default Chrome;
