import createLucideIcon from '../createLucideIcon';

const AlarmPlus = createLucideIcon('AlarmPlus', [
  ['path', { d: 'M12 21a8 8 0 1 0 0-16 8 8 0 0 0 0 16z', key: '1dr9l2' }],
  ['path', { d: 'M5 3 2 6', key: '18tl5t' }],
  ['path', { d: 'm22 6-3-3', key: '1opdir' }],
  ['path', { d: 'm6 19-2 2', key: '1ek6nb' }],
  ['path', { d: 'm18 19 2 2', key: 'lw9i' }],
  ['path', { d: 'M12 10v6', key: '1bos4e' }],
  ['path', { d: 'M9 13h6', key: '1uhe8q' }],
]);

export default AlarmPlus;
