import createLucideIcon from '../createLucideIcon';

const Triangle = createLucideIcon('Triangle', [
  [
    'path',
    {
      d: 'm21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3Z',
      key: 'c3ski4',
    },
  ],
]);

export default Triangle;
