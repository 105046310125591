import createLucideIcon from '../createLucideIcon';

const ShieldOff = createLucideIcon('ShieldOff', [
  [
    'path',
    { d: 'M19.69 14a6.9 6.9 0 0 0 .31-2V5l-8-3-3.16 1.18', key: 'ungvgc' },
  ],
  [
    'path',
    {
      d: 'M4.73 4.73 4 5v7c0 6 8 10 8 10a20.29 20.29 0 0 0 5.62-4.38',
      key: '1qf5yw',
    },
  ],
  ['line', { x1: '2', y1: '2', x2: '22', y2: '22', key: '1w4vcy' }],
]);

export default ShieldOff;
