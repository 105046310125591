import createLucideIcon from '../createLucideIcon';

const WrapText = createLucideIcon('WrapText', [
  ['line', { x1: '3', y1: '6', x2: '21', y2: '6', key: '1tp2lp' }],
  ['path', { d: 'M3 12h15a3 3 0 1 1 0 6h-4', key: '1cl7v7' }],
  ['polyline', { points: '16 16 14 18 16 20', key: '1jznyi' }],
  ['line', { x1: '3', y1: '18', x2: '10', y2: '18', key: '16bh46' }],
]);

export default WrapText;
