import createLucideIcon from '../createLucideIcon';

const Snowflake = createLucideIcon('Snowflake', [
  ['line', { x1: '2', y1: '12', x2: '22', y2: '12', key: 'zvmn4p' }],
  ['line', { x1: '12', y1: '2', x2: '12', y2: '22', key: '1k6o5o' }],
  ['path', { d: 'm20 16-4-4 4-4', key: 'rquw4f' }],
  ['path', { d: 'm4 8 4 4-4 4', key: '12s3z9' }],
  ['path', { d: 'm16 4-4 4-4-4', key: '1tumq1' }],
  ['path', { d: 'm8 20 4-4 4 4', key: '9p200w' }],
]);

export default Snowflake;
