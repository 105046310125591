import createLucideIcon from '../createLucideIcon';

const ServerCog = createLucideIcon('ServerCog', [
  [
    'path',
    {
      d: 'M5 10H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-1',
      key: '1qm4no',
    },
  ],
  [
    'path',
    {
      d: 'M5 14H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-1',
      key: '1lpaho',
    },
  ],
  ['path', { d: 'M6 6h.01', key: '1utrut' }],
  ['path', { d: 'M6 18h.01', key: 'uhywen' }],
  ['circle', { cx: '12', cy: '12', r: '3', key: '1v7zrd' }],
  ['path', { d: 'M12 8v1', key: '1rj8u4' }],
  ['path', { d: 'M12 15v1', key: '1ovrzm' }],
  ['path', { d: 'M16 12h-1', key: '1qpdyp' }],
  ['path', { d: 'M9 12H8', key: '1l15iv' }],
  ['path', { d: 'm15 9-.88.88', key: '3hwatj' }],
  ['path', { d: 'M9.88 14.12 9 15', key: '13ldc9' }],
  ['path', { d: 'm15 15-.88-.88', key: '45priv' }],
  ['path', { d: 'M9.88 9.88 9 9', key: '1ladhj' }],
]);

export default ServerCog;
