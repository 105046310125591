import createLucideIcon from '../createLucideIcon';

const AlignVerticalDistributeStart = createLucideIcon(
  'AlignVerticalDistributeStart',
  [
    [
      'rect',
      { x: '5', y: '14', width: '14', height: '6', rx: '2', key: '1qrzuf' },
    ],
    [
      'rect',
      { x: '7', y: '4', width: '10', height: '6', rx: '2', key: 'we8e9z' },
    ],
    ['path', { d: 'M2 14h20', key: 'myj16y' }],
    ['path', { d: 'M2 4h20', key: 'mda7wb' }],
  ],
);

export default AlignVerticalDistributeStart;
