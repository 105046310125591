import createLucideIcon from '../createLucideIcon';

const AlertOctagon = createLucideIcon('AlertOctagon', [
  [
    'polygon',
    {
      points:
        '7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2',
      key: 'h1p8hx',
    },
  ],
  ['line', { x1: '12', y1: '8', x2: '12', y2: '12', key: '1grbh0' }],
  ['line', { x1: '12', y1: '16', x2: '12.01', y2: '16', key: '1w440g' }],
]);

export default AlertOctagon;
