import createLucideIcon from '../createLucideIcon';

const Aperture = createLucideIcon('Aperture', [
  ['circle', { cx: '12', cy: '12', r: '10', key: '1mglay' }],
  ['line', { x1: '14.31', y1: '8', x2: '20.05', y2: '17.94', key: '1oqej7' }],
  ['line', { x1: '9.69', y1: '8', x2: '21.17', y2: '8', key: '1cv19a' }],
  ['line', { x1: '7.38', y1: '12', x2: '13.12', y2: '2.06', key: '1vh5oz' }],
  ['line', { x1: '9.69', y1: '16', x2: '3.95', y2: '6.06', key: 'saeeuz' }],
  ['line', { x1: '14.31', y1: '16', x2: '2.83', y2: '16', key: 'pq85rp' }],
  ['line', { x1: '16.62', y1: '12', x2: '10.88', y2: '21.94', key: 'wactqi' }],
]);

export default Aperture;
