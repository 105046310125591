import createLucideIcon from '../createLucideIcon';

const ArchiveRestore = createLucideIcon('ArchiveRestore', [
  [
    'rect',
    { x: '2', y: '4', width: '20', height: '5', rx: '2', key: '1h2p0l' },
  ],
  ['path', { d: 'M12 13v7', key: '1arz7h' }],
  ['path', { d: 'm9 16 3-3 3 3', key: '1idcnm' }],
  ['path', { d: 'M4 9v9a2 2 0 0 0 2 2h2', key: 'qxnby6' }],
  ['path', { d: 'M20 9v9a2 2 0 0 1-2 2h-2', key: 'gz3jmx' }],
]);

export default ArchiveRestore;
