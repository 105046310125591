import createLucideIcon from '../createLucideIcon';

const Sword = createLucideIcon('Sword', [
  ['polyline', { points: '14.5 17.5 3 6 3 3 6 3 17.5 14.5', key: '1hfsw2' }],
  ['line', { x1: '13', y1: '19', x2: '19', y2: '13', key: '7h9f57' }],
  ['line', { x1: '16', y1: '16', x2: '20', y2: '20', key: '1b4zco' }],
  ['line', { x1: '19', y1: '21', x2: '21', y2: '19', key: 'df24kr' }],
]);

export default Sword;
