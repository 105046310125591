import createLucideIcon from '../createLucideIcon';

const Indent = createLucideIcon('Indent', [
  ['polyline', { points: '3 8 7 12 3 16', key: 'f3rxhf' }],
  ['line', { x1: '21', y1: '12', x2: '11', y2: '12', key: '1xy73i' }],
  ['line', { x1: '21', y1: '6', x2: '11', y2: '6', key: '97xvqg' }],
  ['line', { x1: '21', y1: '18', x2: '11', y2: '18', key: '1r7j8g' }],
]);

export default Indent;
