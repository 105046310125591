import createLucideIcon from '../createLucideIcon';

const Headphones = createLucideIcon('Headphones', [
  ['path', { d: 'M3 18v-6a9 9 0 0 1 18 0v6', key: 'e2ovd' }],
  [
    'path',
    {
      d: 'M21 19a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3zM3 19a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H3z',
      key: '110y4r',
    },
  ],
]);

export default Headphones;
