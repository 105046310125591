import createLucideIcon from '../createLucideIcon';

const Feather = createLucideIcon('Feather', [
  [
    'path',
    { d: 'M20.24 12.24a6 6 0 0 0-8.49-8.49L5 10.5V19h8.5z', key: 'u4sw5n' },
  ],
  ['line', { x1: '16', y1: '8', x2: '2', y2: '22', key: 'ay4g5i' }],
  ['line', { x1: '17.5', y1: '15', x2: '9', y2: '15', key: 'c4zw0f' }],
]);

export default Feather;
