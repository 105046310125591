import createLucideIcon from '../createLucideIcon';

const Pizza = createLucideIcon('Pizza', [
  ['path', { d: 'M15 11h.01', key: 'rns66s' }],
  ['path', { d: 'M11 15h.01', key: 'k85uqc' }],
  ['path', { d: 'M16 16h.01', key: '1f9h7w' }],
  [
    'path',
    {
      d: 'm2 16 20 6-6-20c-3.36.9-6.42 2.67-8.88 5.12A19.876 19.876 0 0 0 2 16Z',
      key: '1akyvp',
    },
  ],
  ['path', { d: 'M17 6c-6.29 1.47-9.43 5.13-11 11', key: '1dsok0' }],
]);

export default Pizza;
