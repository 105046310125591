import createLucideIcon from '../createLucideIcon';

const Fuel = createLucideIcon('Fuel', [
  ['line', { x1: '3', y1: '22', x2: '15', y2: '22', key: 'fc344c' }],
  ['line', { x1: '4', y1: '9', x2: '14', y2: '9', key: 'htzs8q' }],
  ['path', { d: 'M14 22V4a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v18', key: '16j0yd' }],
  [
    'path',
    {
      d: 'M14 13h2a2 2 0 0 1 2 2v2a2 2 0 0 0 2 2h0a2 2 0 0 0 2-2V9.83a2 2 0 0 0-.59-1.42L18 5',
      key: '8ur5zv',
    },
  ],
]);

export default Fuel;
