import createLucideIcon from '../createLucideIcon';

const FileX = createLucideIcon('FileX', [
  [
    'path',
    {
      d: 'M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z',
      key: '1nnpy2',
    },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['line', { x1: '9.5', y1: '12.5', x2: '14.5', y2: '17.5', key: 'bu76mq' }],
  ['line', { x1: '14.5', y1: '12.5', x2: '9.5', y2: '17.5', key: 'nmydn6' }],
]);

export default FileX;
