import createLucideIcon from '../createLucideIcon';

const FolderCog2 = createLucideIcon('FolderCog2', [
  [
    'path',
    {
      d: 'M4 20h16a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-7.93a2 2 0 0 1-1.66-.9l-.82-1.2A2 2 0 0 0 7.93 3H4a2 2 0 0 0-2 2v13c0 1.1.9 2 2 2Z',
      key: '1fr9dc',
    },
  ],
  ['circle', { cx: '12', cy: '13', r: '2', key: '1c1ljs' }],
  ['path', { d: 'M12 10v1', key: 'ngorzm' }],
  ['path', { d: 'M12 15v1', key: '1ovrzm' }],
  ['path', { d: 'm14.6 11.5-.87.5', key: 'zm6w6e' }],
  ['path', { d: 'm10.27 14-.87.5', key: 'idea33' }],
  ['path', { d: 'm14.6 14.5-.87-.5', key: '1ii18h' }],
  ['path', { d: 'm10.27 12-.87-.5', key: 'tf2vd0' }],
]);

export default FolderCog2;
