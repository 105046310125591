import createLucideIcon from '../createLucideIcon';

const SunMedium = createLucideIcon('SunMedium', [
  ['path', { d: 'M12 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8z', key: '1r4kox' }],
  ['path', { d: 'M12 3v1', key: '1asbbs' }],
  ['path', { d: 'M12 20v1', key: '1wcdkc' }],
  ['path', { d: 'M3 12h1', key: 'lp3yf2' }],
  ['path', { d: 'M20 12h1', key: '1vloll' }],
  ['path', { d: 'm18.364 5.636-.707.707', key: '1hakh0' }],
  ['path', { d: 'm6.343 17.657-.707.707', key: '18m9nf' }],
  ['path', { d: 'm5.636 5.636.707.707', key: '1xv1c5' }],
  ['path', { d: 'm17.657 17.657.707.707', key: 'vl76zb' }],
]);

export default SunMedium;
