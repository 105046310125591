import createLucideIcon from '../createLucideIcon';

const Banknote = createLucideIcon('Banknote', [
  [
    'rect',
    { x: '2', y: '6', width: '20', height: '12', rx: '2', key: '1wpnh2' },
  ],
  ['circle', { cx: '12', cy: '12', r: '2', key: '1c9p78' }],
  ['path', { d: 'M6 12h.01M18 12h.01', key: '113zkx' }],
]);

export default Banknote;
