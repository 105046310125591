import createLucideIcon from '../createLucideIcon';

const FileDigit = createLucideIcon('FileDigit', [
  [
    'path',
    { d: 'M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v4', key: '702lig' },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['path', { d: 'M10 12h2v6', key: '12zw74' }],
  ['rect', { x: '2', y: '12', width: '4', height: '6', key: 'kg2lqm' }],
  ['path', { d: 'M10 18h4', key: '1ulq68' }],
]);

export default FileDigit;
