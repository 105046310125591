import createLucideIcon from '../createLucideIcon';

const Vibrate = createLucideIcon('Vibrate', [
  ['path', { d: 'm2 8 2 2-2 2 2 2-2 2', key: 'sv1b1' }],
  ['path', { d: 'm22 8-2 2 2 2-2 2 2 2', key: '101i4y' }],
  [
    'rect',
    { x: '8', y: '5', width: '8', height: '14', rx: '1', key: 'bi6xeo' },
  ],
]);

export default Vibrate;
