import createLucideIcon from '../createLucideIcon';

const Smartphone = createLucideIcon('Smartphone', [
  [
    'rect',
    {
      x: '5',
      y: '2',
      width: '14',
      height: '20',
      rx: '2',
      ry: '2',
      key: '1gcc4z',
    },
  ],
  ['path', { d: 'M12 18h.01', key: 'mhygvu' }],
]);

export default Smartphone;
