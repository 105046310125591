import createLucideIcon from '../createLucideIcon';

const StretchVertical = createLucideIcon('StretchVertical', [
  [
    'rect',
    { x: '4', y: '2', width: '6', height: '20', rx: '2', key: '1lym67' },
  ],
  [
    'rect',
    { x: '14', y: '2', width: '6', height: '20', rx: '2', key: 'b7v5o0' },
  ],
]);

export default StretchVertical;
