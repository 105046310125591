import createLucideIcon from '../createLucideIcon';

const MicOff = createLucideIcon('MicOff', [
  ['line', { x1: '2', y1: '2', x2: '22', y2: '22', key: '1w4vcy' }],
  ['path', { d: 'M18.89 13.23A7.12 7.12 0 0 0 19 12v-2', key: '80xlxr' }],
  ['path', { d: 'M5 10v2a7 7 0 0 0 12 5', key: 'p2k8kg' }],
  ['path', { d: 'M15 9.34V5a3 3 0 0 0-5.68-1.33', key: '1gzdoj' }],
  ['path', { d: 'M9 9v3a3 3 0 0 0 5.12 2.12', key: 'r2i35w' }],
  ['line', { x1: '12', y1: '19', x2: '12', y2: '22', key: '1l505v' }],
]);

export default MicOff;
