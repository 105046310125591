import createLucideIcon from '../createLucideIcon';

const Dice2 = createLucideIcon('Dice2', [
  [
    'rect',
    {
      x: '3',
      y: '3',
      width: '18',
      height: '18',
      rx: '2',
      ry: '2',
      key: 'maln0c',
    },
  ],
  ['path', { d: 'M15 9h.01', key: 'x1ddxp' }],
  ['path', { d: 'M9 15h.01', key: 'fzyn71' }],
]);

export default Dice2;
