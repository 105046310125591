import createLucideIcon from '../createLucideIcon';

const Bug = createLucideIcon('Bug', [
  [
    'rect',
    { width: '8', height: '14', x: '8', y: '6', rx: '4', key: 'hq8nra' },
  ],
  ['path', { d: 'm19 7-3 2', key: 'fmg8ec' }],
  ['path', { d: 'm5 7 3 2', key: 'dkxqes' }],
  ['path', { d: 'm19 19-3-2', key: '1hbhi4' }],
  ['path', { d: 'm5 19 3-2', key: 'dvt2ee' }],
  ['path', { d: 'M20 13h-4', key: '1agfp2' }],
  ['path', { d: 'M4 13h4', key: '1bwh8b' }],
  ['path', { d: 'm10 4 1 2', key: '1pot59' }],
  ['path', { d: 'm14 4-1 2', key: 'm8sn0o' }],
]);

export default Bug;
