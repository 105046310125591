import createLucideIcon from '../createLucideIcon';

const Gamepad = createLucideIcon('Gamepad', [
  ['line', { x1: '6', y1: '12', x2: '10', y2: '12', key: 'xg250c' }],
  ['line', { x1: '8', y1: '10', x2: '8', y2: '14', key: '1cn0zn' }],
  ['line', { x1: '15', y1: '13', x2: '15.01', y2: '13', key: '1pybt0' }],
  ['line', { x1: '18', y1: '11', x2: '18.01', y2: '11', key: '147dzq' }],
  [
    'rect',
    { x: '2', y: '6', width: '20', height: '12', rx: '2', key: '1wpnh2' },
  ],
]);

export default Gamepad;
