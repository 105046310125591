import createLucideIcon from '../createLucideIcon';

const AlignVerticalSpaceBetween = createLucideIcon(
  'AlignVerticalSpaceBetween',
  [
    [
      'rect',
      { x: '5', y: '15', width: '14', height: '6', rx: '2', key: 'hytrht' },
    ],
    [
      'rect',
      { x: '7', y: '3', width: '10', height: '6', rx: '2', key: 'y09b40' },
    ],
    ['path', { d: 'M2 21h20', key: '1nyx9w' }],
    ['path', { d: 'M2 3h20', key: '91anmk' }],
  ],
);

export default AlignVerticalSpaceBetween;
