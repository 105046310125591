import createLucideIcon from '../createLucideIcon';

const AlignHorizontalSpaceBetween = createLucideIcon(
  'AlignHorizontalSpaceBetween',
  [
    [
      'rect',
      { x: '3', y: '5', width: '6', height: '14', rx: '2', key: 'iybqme' },
    ],
    [
      'rect',
      { x: '15', y: '7', width: '6', height: '10', rx: '2', key: '11q98m' },
    ],
    ['path', { d: 'M3 2v20', key: '1d2pfg' }],
    ['path', { d: 'M21 2v20', key: 'p059bm' }],
  ],
);

export default AlignHorizontalSpaceBetween;
