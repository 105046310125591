import createLucideIcon from '../createLucideIcon';

const AlignVerticalJustifyEnd = createLucideIcon('AlignVerticalJustifyEnd', [
  [
    'rect',
    { x: '5', y: '12', width: '14', height: '6', rx: '2', key: '12nflp' },
  ],
  [
    'rect',
    { x: '7', y: '2', width: '10', height: '6', rx: '2', key: '1dm79a' },
  ],
  ['path', { d: 'M2 22h20', key: '272qi7' }],
]);

export default AlignVerticalJustifyEnd;
