import createLucideIcon from '../createLucideIcon';

const Diamond = createLucideIcon('Diamond', [
  [
    'rect',
    {
      x: '12',
      y: '1',
      width: '15.56',
      height: '15.56',
      rx: '2.41',
      transform: 'rotate(45 12 1)',
      key: 'dtb0qj',
    },
  ],
]);

export default Diamond;
