import createLucideIcon from '../createLucideIcon';

const Youtube = createLucideIcon('Youtube', [
  [
    'path',
    {
      d: 'M12 19c-2.3 0-6.4-.2-8.1-.6-.7-.2-1.2-.7-1.4-1.4-.3-1.1-.5-3.4-.5-5s.2-3.9.5-5c.2-.7.7-1.2 1.4-1.4C5.6 5.2 9.7 5 12 5s6.4.2 8.1.6c.7.2 1.2.7 1.4 1.4.3 1.1.5 3.4.5 5s-.2 3.9-.5 5c-.2.7-.7 1.2-1.4 1.4-1.7.4-5.8.6-8.1.6 0 0 0 0 0 0z',
      key: '1nqccg',
    },
  ],
  ['polygon', { points: '10 15 15 12 10 9', key: '1c7afu' }],
]);

export default Youtube;
