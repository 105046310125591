import createLucideIcon from '../createLucideIcon';

const AlignHorizontalDistributeCenter = createLucideIcon(
  'AlignHorizontalDistributeCenter',
  [
    [
      'rect',
      { x: '4', y: '5', width: '6', height: '14', rx: '2', key: 'ric6yp' },
    ],
    [
      'rect',
      { x: '14', y: '7', width: '6', height: '10', rx: '2', key: '1mr5t1' },
    ],
    ['path', { d: 'M17 22v-5', key: '4b6g73' }],
    ['path', { d: 'M17 7V2', key: 'hnrr36' }],
    ['path', { d: 'M7 22v-3', key: '1r4jpn' }],
    ['path', { d: 'M7 5V2', key: 'liy1u9' }],
  ],
);

export default AlignHorizontalDistributeCenter;
