import createLucideIcon from '../createLucideIcon';

const Tablet = createLucideIcon('Tablet', [
  [
    'rect',
    {
      x: '4',
      y: '2',
      width: '16',
      height: '20',
      rx: '2',
      ry: '2',
      key: '152kg8',
    },
  ],
  ['line', { x1: '12', y1: '18', x2: '12.01', y2: '18', key: '73g4n8' }],
]);

export default Tablet;
