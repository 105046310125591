import createLucideIcon from '../createLucideIcon';

const FileLock2 = createLucideIcon('FileLock2', [
  [
    'path',
    { d: 'M4 5V4a2 2 0 0 1 2-2h8.5L20 7.5V20a2 2 0 0 1-2 2H4', key: 'gwd2r9' },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  [
    'rect',
    { x: '2', y: '13', width: '8', height: '5', rx: '1', key: 'lkev2l' },
  ],
  ['path', { d: 'M8 13v-2a2 2 0 1 0-4 0v2', key: '1pdxzg' }],
]);

export default FileLock2;
