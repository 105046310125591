import createLucideIcon from '../createLucideIcon';

const Radio = createLucideIcon('Radio', [
  ['circle', { cx: '12', cy: '12', r: '2', key: '1c9p78' }],
  ['path', { d: 'M4.93 19.07a10 10 0 0 1 0-14.14', key: 'r41b39' }],
  [
    'path',
    {
      d: 'M7.76 16.24a6 6 0 0 1-1.3-1.95 6 6 0 0 1 0-4.59 6 6 0 0 1 1.3-1.95',
      key: '1pc8et',
    },
  ],
  [
    'path',
    {
      d: 'M16.24 7.76a6 6 0 0 1 1.3 2 6 6 0 0 1 0 4.59 6 6 0 0 1-1.3 1.95',
      key: '8dzjga',
    },
  ],
  ['path', { d: 'M19.07 4.93a10 10 0 0 1 0 14.14', key: '1kegas' }],
]);

export default Radio;
