import createLucideIcon from '../createLucideIcon';

const GitPullRequestDraft = createLucideIcon('GitPullRequestDraft', [
  ['circle', { cx: '18', cy: '18', r: '3', key: '1xkwt0' }],
  ['circle', { cx: '6', cy: '6', r: '3', key: '1lh9wr' }],
  ['path', { d: 'M18 6V5', key: '1oao2s' }],
  ['path', { d: 'M18 11v-1', key: '11c8tz' }],
  ['line', { x1: '6', y1: '9', x2: '6', y2: '21', key: '79th4h' }],
]);

export default GitPullRequestDraft;
