import createLucideIcon from '../createLucideIcon';

const ListEnd = createLucideIcon('ListEnd', [
  ['path', { d: 'M16 12H3', key: '1a2rj7' }],
  ['path', { d: 'M16 6H3', key: '1wxfjs' }],
  ['path', { d: 'M10 18H3', key: '13769t' }],
  ['path', { d: 'M21 6v10a2 2 0 0 1-2 2h-4', key: '1snekz' }],
  ['path', { d: 'm16 16-2 2 2 2', key: 'kkc6pm' }],
]);

export default ListEnd;
