import createLucideIcon from '../createLucideIcon';

const FolderMinus = createLucideIcon('FolderMinus', [
  [
    'path',
    {
      d: 'M4 20h16a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-7.93a2 2 0 0 1-1.66-.9l-.82-1.2A2 2 0 0 0 7.93 3H4a2 2 0 0 0-2 2v13c0 1.1.9 2 2 2Z',
      key: '1fr9dc',
    },
  ],
  ['line', { x1: '9', y1: '13', x2: '15', y2: '13', key: '1nzi25' }],
]);

export default FolderMinus;
