import createLucideIcon from '../createLucideIcon';

const BellOff = createLucideIcon('BellOff', [
  ['path', { d: 'M13.73 21a2 2 0 0 1-3.46 0', key: '6o5tke' }],
  ['path', { d: 'M18.63 13A17.888 17.888 0 0 1 18 8', key: 'd5seqe' }],
  [
    'path',
    { d: 'M6.26 6.26A5.86 5.86 0 0 0 6 8c0 7-3 9-3 9h14', key: 'cae0gx' },
  ],
  ['path', { d: 'M18 8a6 6 0 0 0-9.33-5', key: '4mngwl' }],
  ['path', { d: 'm2 2 20 20', key: '1ooewy' }],
]);

export default BellOff;
