import createLucideIcon from '../createLucideIcon';

const LayoutList = createLucideIcon('LayoutList', [
  ['rect', { x: '3', y: '14', width: '7', height: '7', key: '1omegr' }],
  ['rect', { x: '3', y: '3', width: '7', height: '7', key: '1q9d4d' }],
  ['line', { x1: '14', y1: '4', x2: '21', y2: '4', key: '1klf7b' }],
  ['line', { x1: '14', y1: '9', x2: '21', y2: '9', key: '1kf9x0' }],
  ['line', { x1: '14', y1: '15', x2: '21', y2: '15', key: 's6i7v1' }],
  ['line', { x1: '14', y1: '20', x2: '21', y2: '20', key: 'yxpbil' }],
]);

export default LayoutList;
