import createLucideIcon from '../createLucideIcon';

const BatteryLow = createLucideIcon('BatteryLow', [
  [
    'rect',
    {
      x: '2',
      y: '7',
      width: '16',
      height: '10',
      rx: '2',
      ry: '2',
      key: '5j9scf',
    },
  ],
  ['line', { x1: '22', x2: '22', y1: '11', y2: '13', key: '4dh1rd' }],
  ['line', { x1: '6', x2: '6', y1: '11', y2: '13', key: '1wd6dw' }],
]);

export default BatteryLow;
