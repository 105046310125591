import createLucideIcon from '../createLucideIcon';

const Server = createLucideIcon('Server', [
  [
    'rect',
    {
      x: '2',
      y: '2',
      width: '20',
      height: '8',
      rx: '2',
      ry: '2',
      key: 'e1v5fq',
    },
  ],
  [
    'rect',
    {
      x: '2',
      y: '14',
      width: '20',
      height: '8',
      rx: '2',
      ry: '2',
      key: '10c4lq',
    },
  ],
  ['line', { x1: '6', y1: '6', x2: '6.01', y2: '6', key: '1g0o6g' }],
  ['line', { x1: '6', y1: '18', x2: '6.01', y2: '18', key: 'y2j7fo' }],
]);

export default Server;
