import createLucideIcon from '../createLucideIcon';

const PhoneOff = createLucideIcon('PhoneOff', [
  [
    'path',
    {
      d: 'M10.68 13.31a16 16 0 0 0 3.41 2.6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7 2 2 0 0 1 1.72 2v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.42 19.42 0 0 1-3.33-2.67m-2.67-3.34a19.79 19.79 0 0 1-3.07-8.63A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91',
      key: 'z86iuo',
    },
  ],
  ['line', { x1: '22', y1: '2', x2: '2', y2: '22', key: '1sphze' }],
]);

export default PhoneOff;
