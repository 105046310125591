import createLucideIcon from '../createLucideIcon';

const SlidersHorizontal = createLucideIcon('SlidersHorizontal', [
  ['line', { x1: '21', y1: '4', x2: '14', y2: '4', key: 'ujuyh9' }],
  ['line', { x1: '10', y1: '4', x2: '3', y2: '4', key: '5ejmvt' }],
  ['line', { x1: '21', y1: '12', x2: '12', y2: '12', key: 'inadxn' }],
  ['line', { x1: '8', y1: '12', x2: '3', y2: '12', key: 'apa8u8' }],
  ['line', { x1: '21', y1: '20', x2: '16', y2: '20', key: 'w4k2j3' }],
  ['line', { x1: '12', y1: '20', x2: '3', y2: '20', key: '15hqih' }],
  ['line', { x1: '14', y1: '2', x2: '14', y2: '6', key: '19ksk4' }],
  ['line', { x1: '8', y1: '10', x2: '8', y2: '14', key: '1cn0zn' }],
  ['line', { x1: '16', y1: '18', x2: '16', y2: '22', key: '1vfncj' }],
]);

export default SlidersHorizontal;
