import createLucideIcon from '../createLucideIcon';

const PhoneOutgoing = createLucideIcon('PhoneOutgoing', [
  ['polyline', { points: '22 8 22 2 16 2', key: '1g204g' }],
  ['line', { x1: '16', y1: '8', x2: '22', y2: '2', key: '1hkegm' }],
  [
    'path',
    {
      d: 'M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z',
      key: 'foiqr5',
    },
  ],
]);

export default PhoneOutgoing;
