import createLucideIcon from '../createLucideIcon';

const Bot = createLucideIcon('Bot', [
  [
    'rect',
    { x: '3', y: '11', width: '18', height: '10', rx: '2', key: 'qbqwso' },
  ],
  ['circle', { cx: '12', cy: '5', r: '2', key: 'f1ur92' }],
  ['path', { d: 'M12 7v4', key: 'xawao1' }],
  ['line', { x1: '8', y1: '16', x2: '8', y2: '16', key: '717jkb' }],
  ['line', { x1: '16', y1: '16', x2: '16', y2: '16', key: '19gr12' }],
]);

export default Bot;
