import createLucideIcon from '../createLucideIcon';

const Slack = createLucideIcon('Slack', [
  [
    'rect',
    { x: '13', y: '2', width: '3', height: '8', rx: '1.5', key: '134gbe' },
  ],
  ['path', { d: 'M19 8.5V10h1.5A1.5 1.5 0 1 0 19 8.5', key: '183iwg' }],
  [
    'rect',
    { x: '8', y: '14', width: '3', height: '8', rx: '1.5', key: '6p48jh' },
  ],
  ['path', { d: 'M5 15.5V14H3.5A1.5 1.5 0 1 0 5 15.5', key: '76g71w' }],
  [
    'rect',
    { x: '14', y: '13', width: '8', height: '3', rx: '1.5', key: '1gabf9' },
  ],
  ['path', { d: 'M15.5 19H14v1.5a1.5 1.5 0 1 0 1.5-1.5', key: 'jc4sz0' }],
  [
    'rect',
    { x: '2', y: '8', width: '8', height: '3', rx: '1.5', key: '1bingn' },
  ],
  ['path', { d: 'M8.5 5H10V3.5A1.5 1.5 0 1 0 8.5 5', key: '16f3cl' }],
]);

export default Slack;
