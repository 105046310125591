import createLucideIcon from '../createLucideIcon';

const Lightbulb = createLucideIcon('Lightbulb', [
  ['line', { x1: '9', y1: '18', x2: '15', y2: '18', key: 'poumom' }],
  ['line', { x1: '10', y1: '22', x2: '14', y2: '22', key: '1oekqc' }],
  [
    'path',
    {
      d: 'M15.09 14c.18-.98.65-1.74 1.41-2.5A4.65 4.65 0 0 0 18 8 6 6 0 0 0 6 8c0 1 .23 2.23 1.5 3.5A4.61 4.61 0 0 1 8.91 14',
      key: 'a9yf0y',
    },
  ],
]);

export default Lightbulb;
