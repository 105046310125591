import createLucideIcon from '../createLucideIcon';

const AlignEndHorizontal = createLucideIcon('AlignEndHorizontal', [
  [
    'rect',
    { x: '4', y: '2', width: '6', height: '16', rx: '2', key: 'xp5u6c' },
  ],
  [
    'rect',
    { x: '14', y: '9', width: '6', height: '9', rx: '2', key: '1e039c' },
  ],
  ['path', { d: 'M22 22H2', key: '19qnx5' }],
]);

export default AlignEndHorizontal;
