import createLucideIcon from '../createLucideIcon';

const ClipboardSignature = createLucideIcon('ClipboardSignature', [
  [
    'rect',
    {
      x: '8',
      y: '2',
      width: '8',
      height: '4',
      rx: '1',
      ry: '1',
      key: 'wz2j3u',
    },
  ],
  [
    'path',
    {
      d: 'M8 4H6a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-.5',
      key: '1but9f',
    },
  ],
  ['path', { d: 'M16 4h2a2 2 0 0 1 1.73 1', key: '1p8n7l' }],
  [
    'path',
    {
      d: 'M18.42 9.61a2.1 2.1 0 1 1 2.97 2.97L16.95 17 13 18l.99-3.95 4.43-4.44Z',
      key: 'johvi5',
    },
  ],
  ['path', { d: 'M8 18h1', key: '13wk12' }],
]);

export default ClipboardSignature;
