import createLucideIcon from '../createLucideIcon';

const StarOff = createLucideIcon('StarOff', [
  [
    'path',
    {
      d: 'M8.34 8.34 2 9.27l5 4.87L5.82 21 12 17.77 18.18 21l-.59-3.43',
      key: '16m0ql',
    },
  ],
  [
    'path',
    { d: 'M18.42 12.76 22 9.27l-6.91-1L12 2l-1.44 2.91', key: '1vt8nq' },
  ],
  ['line', { x1: '2', y1: '2', x2: '22', y2: '22', key: '1w4vcy' }],
]);

export default StarOff;
