import createLucideIcon from '../createLucideIcon';

const FileCog = createLucideIcon('FileCog', [
  [
    'path',
    { d: 'M4 6V4a2 2 0 0 1 2-2h8.5L20 7.5V20a2 2 0 0 1-2 2H4', key: 'dba9qu' },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['circle', { cx: '6', cy: '14', r: '3', key: 'a1xfv6' }],
  ['path', { d: 'M6 10v1', key: 'xs0f9j' }],
  ['path', { d: 'M6 17v1', key: 'idyhc0' }],
  ['path', { d: 'M10 14H9', key: 'm5fm2q' }],
  ['path', { d: 'M3 14H2', key: '19ot09' }],
  ['path', { d: 'm9 11-.88.88', key: 'lhul2b' }],
  ['path', { d: 'M3.88 16.12 3 17', key: '169z9n' }],
  ['path', { d: 'm9 17-.88-.88', key: '5io96w' }],
  ['path', { d: 'M3.88 11.88 3 11', key: '1ynhy1' }],
]);

export default FileCog;
