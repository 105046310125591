import createLucideIcon from '../createLucideIcon';

const Keyboard = createLucideIcon('Keyboard', [
  [
    'rect',
    {
      x: '2',
      y: '4',
      width: '20',
      height: '16',
      rx: '2',
      ry: '2',
      key: 'xgg3gf',
    },
  ],
  ['path', { d: 'M6 8h.001', key: '1ej0i3' }],
  ['path', { d: 'M10 8h.001', key: '1x2st2' }],
  ['path', { d: 'M14 8h.001', key: '1vkmyp' }],
  ['path', { d: 'M18 8h.001', key: 'kfsenl' }],
  ['path', { d: 'M8 12h.001', key: '1sjpby' }],
  ['path', { d: 'M12 12h.001', key: 'al75ts' }],
  ['path', { d: 'M16 12h.001', key: '931bgk' }],
  ['path', { d: 'M7 16h10', key: 'wp8him' }],
]);

export default Keyboard;
