import createLucideIcon from '../createLucideIcon';

const AlignVerticalJustifyStart = createLucideIcon(
  'AlignVerticalJustifyStart',
  [
    [
      'rect',
      { x: '5', y: '16', width: '14', height: '6', rx: '2', key: '1xmr5l' },
    ],
    [
      'rect',
      { x: '7', y: '6', width: '10', height: '6', rx: '2', key: 'q2ofyd' },
    ],
    ['path', { d: 'M2 2h20', key: '1ennik' }],
  ],
);

export default AlignVerticalJustifyStart;
