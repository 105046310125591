import createLucideIcon from '../createLucideIcon';

const AlignHorizontalJustifyEnd = createLucideIcon(
  'AlignHorizontalJustifyEnd',
  [
    [
      'rect',
      { x: '2', y: '5', width: '6', height: '14', rx: '2', key: '15angl' },
    ],
    [
      'rect',
      { x: '12', y: '7', width: '6', height: '10', rx: '2', key: '150rwc' },
    ],
    ['path', { d: 'M22 2v20', key: '40qfg1' }],
  ],
);

export default AlignHorizontalJustifyEnd;
