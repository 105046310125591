import createLucideIcon from '../createLucideIcon';

const PinOff = createLucideIcon('PinOff', [
  ['line', { x1: '2', y1: '2', x2: '22', y2: '22', key: '1w4vcy' }],
  ['line', { x1: '12', y1: '17', x2: '12', y2: '22', key: 'fb3qrx' }],
  [
    'path',
    {
      d: 'M9 9v1.76a2 2 0 0 1-1.11 1.79l-1.78.9A2 2 0 0 0 5 15.24V17h12',
      key: '13x2n8',
    },
  ],
  ['path', { d: 'M15 9.34V6h1a2 2 0 0 0 0-4H7.89', key: 'reo3ki' }],
]);

export default PinOff;
