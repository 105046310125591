import createLucideIcon from '../createLucideIcon';

const Heart = createLucideIcon('Heart', [
  [
    'path',
    {
      d: 'M20.42 4.58a5.4 5.4 0 0 0-7.65 0l-.77.78-.77-.78a5.4 5.4 0 0 0-7.65 0C1.46 6.7 1.33 10.28 4 13l8 8 8-8c2.67-2.72 2.54-6.3.42-8.42z',
      key: '3q7jk9',
    },
  ],
]);

export default Heart;
