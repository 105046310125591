import createLucideIcon from '../createLucideIcon';

const Scroll = createLucideIcon('Scroll', [
  [
    'path',
    {
      d: 'M10 17v2a2 2 0 0 1-2 2v0a2 2 0 0 1-2-2V5a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v3h3',
      key: '1knuaj',
    },
  ],
  ['path', { d: 'M22 17v2a2 2 0 0 1-2 2H8', key: '62edg8' }],
  ['path', { d: 'M19 17V5a2 2 0 0 0-2-2H4', key: 'zz82l3' }],
  ['path', { d: 'M22 17H10', key: '1dr9mz' }],
]);

export default Scroll;
