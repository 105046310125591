import createLucideIcon from '../createLucideIcon';

const SunMoon = createLucideIcon('SunMoon', [
  ['path', { d: 'M12 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8z', key: '1r4kox' }],
  ['path', { d: 'M12 8a2.828 2.828 0 1 0 4 4', key: '16688u' }],
  ['path', { d: 'M12 2v2', key: 'tus03m' }],
  ['path', { d: 'M12 20v2', key: '1lh1kg' }],
  ['path', { d: 'm4.93 4.93 1.41 1.41', key: '149t6j' }],
  ['path', { d: 'm17.66 17.66 1.41 1.41', key: 'ptbguv' }],
  ['path', { d: 'M2 12h2', key: '1t8f8n' }],
  ['path', { d: 'M20 12h2', key: '1q8mjw' }],
  ['path', { d: 'm6.34 17.66-1.41 1.41', key: '1m8zz5' }],
  ['path', { d: 'm19.07 4.93-1.41 1.41', key: '1shlcs' }],
]);

export default SunMoon;
