import createLucideIcon from '../createLucideIcon';

const UserX = createLucideIcon('UserX', [
  ['path', { d: 'M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2', key: '1yyitq' }],
  ['circle', { cx: '9', cy: '7', r: '4', key: 'nufk8' }],
  ['line', { x1: '17', y1: '8', x2: '22', y2: '13', key: '10apcb' }],
  ['line', { x1: '22', y1: '8', x2: '17', y2: '13', key: '1l8di5' }],
]);

export default UserX;
