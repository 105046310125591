import createLucideIcon from '../createLucideIcon';

const WineOff = createLucideIcon('WineOff', [
  ['path', { d: 'M8 22h8', key: 'rmew8v' }],
  ['path', { d: 'M7 10h3m7 0h-1.343', key: 'v48bem' }],
  ['path', { d: 'M12 15v7', key: 't2xh3l' }],
  [
    'path',
    {
      d: 'M7.307 7.307A12.33 12.33 0 0 0 7 10a5 5 0 0 0 7.391 4.391M8.638 2.981C8.75 2.668 8.872 2.34 9 2h6c1.5 4 2 6 2 8 0 .407-.05.809-.145 1.198',
      key: '1ymjlu',
    },
  ],
  ['line', { x1: '2', y1: '2', x2: '22', y2: '22', key: '1w4vcy' }],
]);

export default WineOff;
