import createLucideIcon from '../createLucideIcon';

const FileCode = createLucideIcon('FileCode', [
  [
    'path',
    { d: 'M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v4', key: '702lig' },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['path', { d: 'm9 18 3-3-3-3', key: '112psh' }],
  ['path', { d: 'm5 12-3 3 3 3', key: 'oke12k' }],
]);

export default FileCode;
