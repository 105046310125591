import createLucideIcon from '../createLucideIcon';

const Bus = createLucideIcon('Bus', [
  [
    'path',
    {
      d: 'M19 17h2l.64-2.54c.24-.959.24-1.962 0-2.92l-1.07-4.27A3 3 0 0 0 17.66 5H4a2 2 0 0 0-2 2v10h2',
      key: 'wfsdqh',
    },
  ],
  ['path', { d: 'M14 17H9', key: 'o2noo5' }],
  ['circle', { cx: '6.5', cy: '17.5', r: '2.5', key: 'gc8oob' }],
  ['circle', { cx: '16.5', cy: '17.5', r: '2.5', key: '4btu0q' }],
]);

export default Bus;
