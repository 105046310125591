import createLucideIcon from '../createLucideIcon';

const ClipboardType = createLucideIcon('ClipboardType', [
  [
    'rect',
    {
      x: '8',
      y: '2',
      width: '8',
      height: '4',
      rx: '1',
      ry: '1',
      key: 'wz2j3u',
    },
  ],
  [
    'path',
    {
      d: 'M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2',
      key: '116196',
    },
  ],
  ['path', { d: 'M9 12v-1h6v1', key: 'iehl6m' }],
  ['path', { d: 'M11 17h2', key: '12w5me' }],
  ['path', { d: 'M12 11v6', key: '1bwqyc' }],
]);

export default ClipboardType;
