import createLucideIcon from '../createLucideIcon';

const Subtitles = createLucideIcon('Subtitles', [
  ['path', { d: 'M7 13h4', key: '1m1xj0' }],
  ['path', { d: 'M15 13h2', key: 'vgjay3' }],
  ['path', { d: 'M7 9h2', key: '1q072n' }],
  ['path', { d: 'M13 9h4', key: 'o7fxw0' }],
  [
    'path',
    {
      d: 'M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v10Z',
      key: '12yqn6',
    },
  ],
]);

export default Subtitles;
