import createLucideIcon from '../createLucideIcon';

const Baby = createLucideIcon('Baby', [
  ['path', { d: 'M9 12h0.01', key: 'u5n1lm' }],
  ['path', { d: 'M15 12h0.01', key: '10zk70' }],
  ['path', { d: 'M10 16c.5.3 1.2.5 2 .5s1.5-.2 2-.5', key: '1u7htd' }],
  [
    'path',
    {
      d: 'M19 6.3a9 9 0 0 1 1.8 3.9 2 2 0 0 1 0 3.6 9 9 0 0 1-17.6 0 2 2 0 0 1 0-3.6A9 9 0 0 1 12 3c2 0 3.5 1.1 3.5 2.5s-.9 2.5-2 2.5c-.8 0-1.5-.4-1.5-1',
      key: '5yv0yz',
    },
  ],
]);

export default Baby;
